@font-face {
  font-family: 'InterTight';
  src: url('../assets/fonts/InterTight-Italic-VariableFont_wght.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'InterTight';
  src: url('../assets/fonts/InterTight-VariableFont_wght.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'InterTight';
  src: url('../assets/fonts/InterTight-Italic-VariableFont_wght.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'InterTight';
  src: url('../assets/fonts/InterTight-VariableFont_wght.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'InterTight';
  src: url('../assets/fonts/InterTight-Italic-VariableFont_wght.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'InterTight';
  src: url('../assets/fonts/InterTight-VariableFont_wght.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'InterTight';
  src: url('../assets/fonts/InterTight-Italic-VariableFont_wght.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'InterTight';
  src: url('../assets/fonts/InterTight-VariableFont_wght.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'InterTight';
  src: url('../assets/fonts/InterTight-Italic-VariableFont_wght.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'InterTight';
  src: url('../assets/fonts/InterTight-VariableFont_wght.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'InterTight';
  src: url('../assets/fonts/InterTight-Italic-VariableFont_wght.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'InterTight';
  src: url('../assets/fonts/InterTight-VariableFont_wght.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'InterTight';
  src: url('../assets/fonts/InterTight-Italic-VariableFont_wght.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'InterTight';
  src: url('../assets/fonts/InterTight-VariableFont_wght.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'InterTight';
  src: url('../assets/fonts/InterTight-Italic-VariableFont_wght.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'InterTight';
  src: url('../assets/fonts/InterTight-VariableFont_wght.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

:root {
  --purple: #262261;
  --blue: #3467a9;
  --red: #e1426f;
  --grey: #f5f5f5;
  --paddingxl: 150px;
  --paddingl: 50px;
  --paddings: 25px;
}

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

html,
body {
  width: 100%;
  height: 100%;
}

html {
  overscroll-behavior: none;
}

body {
  font-family: 'InterTight', sans-serif;
}

img,
picture,
video,
canvas,
svg {
  display: block;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

button {
  cursor: pointer;
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  font-weight: 700;
  -webkit-transition: transform 0.35s ease-out;
  -moz-transition: transform 0.35s ease-out;
  -ms-transition: transform 0.35s ease-out;
  -o-transition: transform 0.35s ease-out;
  transition: transform 0.35s ease-out;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  letter-spacing: -0.03em;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  line-height: 1;
  overflow-wrap: break-word;
}

th,
td,
p {
  font-size: 1rem;
  line-height: 150%;
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

button,
input[type='submit'],
input[type='reset'] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: inherit;
  cursor: pointer;
}