.password {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;

    .password__form {
        background-color: white;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        width: 300px;
    }

    .password__form label {
        display: block;
        margin-bottom: 10px;
    }

    .password__form input {
        width: 100%;
        padding: 8px;
        font-size: 14px;
        border: 1px solid #ccc;
        border-radius: 4px;
    }

    .password__form button {
        width: 100%;
        padding: 10px;
        background-color: var(--red);
        color: white;
        border: none;
        border-radius: 4px;
        border: 2px solid var(--red);
        cursor: pointer;
    }

    .password__form button:hover {
        background-color: #fff;
        color: var(--red);
        border: 2px solid var(--red);
    }
}