.project {
    overflow-x: hidden;
    z-index: 1;

    .project__summary {
        position: relative;
        width: 100vw;
        height: 100vh;

        .project__banner {
            position: relative;
            width: 100vw;
            height: 100vh;

            .project__overlay {
                position: absolute;
                width: 100%;
                height: 100%;
                mix-blend-mode: multiply;
            }

            .project__image {
                position: absolute;
                width: 100%;
                height: 100%;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center center;
                mix-blend-mode: multiply;
            }
        }

        .project__title {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 100%;

            h1 {
                text-transform: uppercase;
                font-weight: 900;
                color: #fff;
            }
        }
    }

    .project__content {
        width: 100%;
        display: flex;
        flex-direction: row;

        .pin-spacer {
            display: none
        }

        .project__overview {
            top: 0;
            height: 100vh;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            width: 25%;
            padding: var(--paddingl);
            gap: var(--paddingl);
            background: white;
            overflow: auto;
            color: var(--purple);

            h2 {
                font-weight: 900;
                margin-bottom: 5px;
            }

            h3 {
                font-weight: 300;
            }

            a {
                &:hover {
                    transform: translateY(-5px);
                    -webkit-transform: translateY(-5px);
                    -moz-transform: translateY(-5px);
                    -ms-transform: translateY(-5px);
                    -o-transform: translateY(-5px);
                }
            }
        }

        .project__details {
            display: flex;
            flex-direction: column;
            width: 75%;
            overflow-y: auto;
            padding: var(--paddingl);
            gap: var(--paddingl);

            .section {
                opacity: 1;
                width: 100%;
                box-sizing: border-box;

                img,
                video {
                    width: 100%;
                    display: block;
                }
            }
        }

        .project__navigation {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-end;
            padding-top: var(--paddingxl);
            color: var(--purple);

            p {
                font-weight: 300;
                text-transform: lowercase;
            }

            button {
                font-weight: 900;
                text-transform: uppercase;
                text-align: right;
                word-break: break-word;

                &::after {
                    display: inline-block;
                    content: "\2192";
                    -webkit-transition: transform 0.35s ease-out;
                    -moz-transition: transform 0.35s ease-out;
                    -ms-transition: transform 0.35s ease-out;
                    -o-transition: transform 0.35s ease-out;
                    transition: transform 0.35s ease-out;
                }

                &:hover {
                    &::after {
                        -webkit-transform: translateX(12px);
                        -moz-transform: translateX(12px);
                        -ms-transform: translateX(12px);
                        -o-transform: translateX(12px);
                        transform: translateX(12px);
                    }
                }
            }
        }
    }

}

// mobile layout starts here
@media (max-width: 596px) {
    .project {
        .project__summary {
            .project__title {
                padding: var(--paddings);

                h1 {
                    font-size: 3rem;
                }
            }
        }

        .project__content {
            .project__overview {
                padding: var(--paddings);
                gap: var(--paddings);

                h2 {
                    font-size: 2rem;
                    margin-bottom: 5px;
                }

                h3 {
                    font-size: 1.5rem;
                }
            }

            .project__details {
                padding: var(--paddings);
                gap: var(--paddings);
            }

            .project__navigation {
                padding-top: var(--paddingl);

                p {
                    font-size: 1.5rem;
                }

                button {
                    font-size: 2rem;
                }
            }
        }

    }
}

@media (min-width: 597px) and (max-width: 768px) {
    .project {
        .project__summary {
            .project__title {
                padding: var(--paddings);

                h1 {
                    font-size: 3.5rem;
                }
            }
        }

        .project__content {
            .project__overview {
                padding: var(--paddings);
                gap: var(--paddings);

                h2 {
                    font-size: 2.5rem;
                    margin-bottom: 5px;
                }

                h3 {
                    font-size: 2rem;
                }
            }

            .project__details {
                padding: var(--paddings);
                gap: var(--paddings);
            }

            .project__navigation {
                padding-top: var(--paddingl);

                p {
                    font-size: 2rem;
                }

                button {
                    font-size: 2.5rem;
                }
            }
        }

    }
}

@media (min-width: 769px) and (max-width: 992px) {
    .project {
        .project__summary {
            .project__title {
                padding: var(--paddings);

                h1 {
                    font-size: 4rem;
                }
            }
        }

        .project__content {
            .project__overview {
                padding: var(--paddings);
                gap: var(--paddings);

                h2 {
                    font-size: 2.75rem;
                    margin-bottom: 5px;
                }

                h3 {
                    font-size: 2.5rem;
                }
            }

            .project__details {
                padding: var(--paddings);
                gap: var(--paddings);
            }

            .project__navigation {
                padding-top: var(--paddingl);

                p {
                    font-size: 2.5rem;
                }

                button {
                    font-size: 2.75rem;
                }
            }
        }

    }
}

@media (max-width: 992px) {
    .project {
        .project__summary {
            // height: 50vh;

            .project__banner {
                // height: 50vh;
            }

            .project__title {
                top: 50%;
                left: 50%;
                height: max-content;
                padding: var(--paddings);
                text-align: center;
                -webkit-transform: translate(-50%, -0%);
                -moz-transform: translate(-50%, -0%);
                -ms-transform: translate(-50%, -0%);
                -o-transform: translate(-50%, -0%);
                transform: translate(-50%, -0%);
            }
        }

        .project__content {
            flex-direction: column;

            .project__overview {
                width: 100%;
                height: max-content;
            }

            .project__details {
                width: 100%;
                position: relative;
                height: auto;
            }
        }
    }
}

// desktop layout starts here
@media (min-width: 993px) and (max-width: 1200px) {
    .project {
        .project__summary {
            .project__title {
                padding: var(--paddingl);

                h1 {
                    font-size: 4.5rem;
                }
            }
        }

        .project__content {
            .project__overview {
                width: 35%;
                padding: var(--paddingl);
                gap: var(--paddingl);

                h2 {
                    font-size: 2.75rem;
                    margin-bottom: 5px;
                }

                h3 {
                    font-size: 2.5rem;
                }
            }

            .project__details {
                width: 65%;
                padding: var(--paddingl);
                gap: var(--paddingl);

                .section {
                    opacity: 0;
                }
            }

            .project__navigation {
                padding-top: var(--paddingxl);

                p {
                    font-size: 2.5rem;
                }

                button {
                    font-size: 4.5rem;
                }
            }
        }

    }
}

@media (min-width: 1201px) {
    .project {
        .project__summary {
            .project__title {
                padding: var(--paddingl);

                h1 {
                    font-size: 7.5rem;
                }
            }
        }

        .project__content {
            .project__overview {
                padding: var(--paddingl);
                gap: var(--paddingl);

                h2 {
                    font-size: 2rem;
                    font-weight: 900;
                    margin-bottom: 5px;
                }

                h3 {
                    font-size: 1.5rem;
                    font-weight: 300;
                }
            }

            .project__details {
                padding: var(--paddingl);
                gap: var(--paddingl);

                .section {
                    opacity: 0;
                }

            }

            .project__navigation {
                padding-top: var(--paddingxl);

                p {
                    font-size: 2.5rem;
                }

                button {
                    font-size: 5rem;
                }
            }
        }

    }
}