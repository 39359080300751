.landing {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: var(--purple);
    overflow: hidden;

    .landing__description {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 999;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #fff;
        text-align: center;
        width: max-content;
        cursor: pointer;
        transition: 0.35s all;

        &:hover {
            transform: translate(-50%, -55%) translateY(-5%);
        }

        h2 {
            text-transform: uppercase;
            font-size: 7.5rem;
            font-weight: 900;
            white-space: nowrap;
        }

        h3 {
            font-size: 2rem;
            font-weight: 300;
        }

        h4 {
            font-size: 1.5rem;
            font-weight: 200;
        }
    }

    .landing__container {
        aspect-ratio: 16/9;
        position: relative;
        margin: 0 auto;
        max-width: 100vw;

        .landing__carousel {
            height: 100%;
            width: 100%;
            position: absolute;
            transform-style: preserve-3d;
            transition: transform 1s;
            z-index: 998;

            div {
                display: block;
                position: absolute;
                aspect-ratio: 16/9;
                opacity: 0.95;
                border-radius: 20px;
                background-size: cover;
                background-position: center;
                transform-origin: center;
                transition: transform 0.35s;
                cursor: pointer;
            }
        }
    }


    .landing__controls {
        position: absolute;
        top: 75%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: var(--paddings);
        z-index: 9999;

        .next,
        .prev {
            color: var(--red);
            cursor: pointer;
            aspect-ratio: 1/1;
            background: #fff;
            border-radius: 10px;
            padding: var(--paddings);
            font-size: 2rem;
            cursor: pointer;
        }

        .next:hover,
        .prev:hover {
            color: #fff;
            background-color: var(--red);
        }

    }
}

@media (max-width: 596px) {
    .landing {
        .landing__description {
            top: 25%;

            h2 {
                font-size: 2.5rem;
                white-space: wrap;
            }

            h3 {
                font-size: 1.5rem;
            }

            h4 {
                font-size: 1rem;
            }
        }

        .landing__container {
            .landing__carousel {
                div {
                    border-radius: 10px;
                }
            }
        }


        .landing__controls {

            .next,
            .prev {
                border-radius: 10px;
                padding: 1rem;
                font-size: 1rem;
            }

        }
    }
}

@media (min-width: 597px) and (max-width: 768px) {
    .landing {
        .landing__description {
            top: 25%;

            h2 {
                font-size: 3.25rem;
                white-space: wrap;
            }

            h3 {
                font-size: 1.75rem;
            }

            h4 {
                font-size: 1.25rem;
            }
        }

        .landing__container {
            .landing__carousel {
                div {
                    border-radius: 10px;
                }
            }
        }


        .landing__controls {

            .next,
            .prev {
                border-radius: 10px;
                padding: 1.25rem;
                font-size: 1.25rem;
            }

        }
    }
}

@media (min-width: 769px) and (max-width: 992px) {
    .landing {
        .landing__description {
            top: 50%;

            h2 {
                font-size: 3.5rem;
                white-space: nowrap;
            }

            h3 {
                font-size: 2rem;
            }

            h4 {
                font-size: 1.5rem;
            }
        }

        .landing__container {
            .landing__carousel {
                div {
                    border-radius: 15px;
                }
            }
        }


        .landing__controls {

            .next,
            .prev {
                border-radius: 10px;
                padding: 1.5rem;
                font-size: 1.5rem;
            }

        }
    }
}

@media (min-width: 993px) and (max-width: 1200px) {
    .landing {
        .landing__description {
            top: 50%;

            h2 {
                font-size: 5rem;
                white-space: nowrap;
            }

            h3 {
                font-size: 2.25rem;
            }

            h4 {
                font-size: 1.25rem;
            }
        }

        .landing__container {
            .landing__carousel {
                div {
                    border-radius: 20px;
                }
            }
        }


        .landing__controls {

            .next,
            .prev {
                border-radius: 10px;
                padding: 1.25rem;
                font-size: 1.25rem;
            }

        }
    }
}

@media (min-width: 1201px) {
    .landing {
        .landing__description {
            h2 {
                font-size: 7.5rem;
                // white-space: nowrap;
            }

            h3 {
                font-size: 2.5rem;
            }

            h4 {
                font-size: 1.5rem;
            }
        }

        .landing__container {
            .landing__carousel {
                div {
                    border-radius: 20px;
                }
            }
        }


        .landing__controls {
            top: 85%;

            .next,
            .prev {
                border-radius: 10px;
                padding: 1.5rem;
                font-size: 1.5rem;
            }

        }
    }
}