.header {
    position: fixed;
    display: flex;
    flex-direction: column;
    top: auto;
    bottom: 0;
    width: 100%;
    height: max-content;
    padding: var(--paddingl);
    gap: var(--paddings);
    color: var(--red);
    z-index: 999;

    .header__top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        text-transform: uppercase;
        font-size: 2.5rem;
        font-weight: 900;

        .header__logo {
            transition: 0.35s all ease;
            -webkit-transition: 0.35s all ease;
            -moz-transition: 0.35s all ease;
            -ms-transition: 0.35s all ease;
            -o-transition: 0.35s all ease;

            &:hover {
                -webkit-text-fill-color: transparent;
                -webkit-text-stroke: thin var(--red);
                transform: translateY(-5px);
                -webkit-transform: translateY(-5px);
                -moz-transform: translateY(-5px);
                -ms-transform: translateY(-5px);
                -o-transform: translateY(-5px);
            }
        }

        .header__social {
            display: flex;
            flex-direction: row;
            gap: var(--paddings);
            padding: 0;
            margin: 0;

            a {
                font-size: 1rem;

                &:hover {
                    transform: translateY(-5px);
                    -webkit-transform: translateY(-5px);
                    -moz-transform: translateY(-5px);
                    -ms-transform: translateY(-5px);
                    -o-transform: translateY(-5px);
                }
            }
        }

        .header__hamburger {
            position: relative;
            width: 40px;
            height: 32px;
            z-index: 9999;

            div {
                position: absolute;
                width: 40px;
                height: 4px;
                background-color: var(--red);
                transition: 0.35s;
                -webkit-transition: 0.35s;
                -moz-transition: 0.35s;
                -ms-transition: 0.35s;
                -o-transition: 0.35s;

                &:nth-child(1) {
                    top: 0;
                }

                &:nth-child(2) {
                    top: 50%;
                    -webkit-transform: translateY(-50%);
                    -moz-transform: translateY(-50%);
                    -ms-transform: translateY(-50%);
                    -o-transform: translateY(-50%);
                    transform: translateY(-50%);
                }

                &:nth-child(3) {
                    bottom: 0;
                }
            }
        }
    }

    .header__bottom {
        display: flex;
        justify-content: space-between;
        align-items: top;
        line-height: var(--paddings);
        opacity: 1;
        transition: opacity 1.05s ease;
        -webkit-transition: opacity 1.05s ease;
        -moz-transition: opacity 1.05s ease;
        -ms-transition: opacity 1.05s ease;
        -o-transition: opacity 1.05s ease;

        .header__content {
            display: flex;
            flex-direction: row;
            gap: var(--paddingl);
        }
    }

    &.open {
        .header__top {
            z-index: 999;

            .header__logo {
                color: var(--purple);

                &:hover {
                    -webkit-text-stroke: thin var(--purple);
                }
            }

            .header__hamburger {
                div {
                    background-color: var(--purple);

                    &:nth-child(1) {
                        transform: rotate(45deg);
                        -webkit-transform: rotate(45deg);
                        -moz-transform: rotate(45deg);
                        -ms-transform: rotate(45deg);
                        -o-transform: rotate(45deg);
                        top: 50%;
                        transform: translateY(-50%) rotate(45deg);
                    }

                    &:nth-child(2) {
                        opacity: 0;
                    }

                    &:nth-child(3) {
                        transform: rotate(-45deg);
                        -webkit-transform: rotate(-45deg);
                        -moz-transform: rotate(-45deg);
                        -ms-transform: rotate(-45deg);
                        -o-transform: rotate(-45deg);
                        top: 50%;
                        transform: translateY(-50%) rotate(-45deg);
                    }
                }
            }
        }

        .header__bottom {
            position: absolute;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-content: space-between;
            background-color: var(--red);
            color: #fff;
            gap: var(--paddingl);
            text-align: right;
            opacity: 1 !important;
            transition: opacity 0.7s all ease;
            -webkit-transition: opacity 0.7s all ease;
            -moz-transition: opacity 0.7s all ease;
            -ms-transition: opacity 0.7s all ease;
            -o-transition: opacity 0.7s all ease;

            .header__content {
                padding: var(--paddings);
                text-align: right;
                flex-direction: column;
                align-content: space-between;
            }

            .header__copyright {
                width: 100vw;
                position: absolute;
                text-align: right;
                bottom: 0;
                padding: var(--paddingl) var(--paddings);
            }
        }
    }
}

// Mobile header starts here
@media (max-width: 596px) {
    .header {
        padding: var(--paddings);

        .header__top {
            font-size: 1.5rem;

            .header__logo {
                font-size: 1.5rem;
            }

            .header__hamburger {
                width: 25px;
                height: 20px;

                div {
                    width: 25px;
                    height: 3px;
                }
            }
        }

        .header__bottom {
            .header__content {
                gap: var(--paddingl);

                .header__social {
                    li {
                        font-size: 3.5rem;
                        padding: 1.5rem 0;
                    }
                }
            }

            .header__copyright {
                li {
                    p {
                        font-size: 0.75rem;
                    }
                }
            }
        }

        &.open {
            .header__bottom {
                gap: var(--paddingl);
            }
        }
    }
}

@media (min-width: 597px) and (max-width: 768px) {
    .header {
        padding: var(--paddings);

        .header__top {
            font-size: 2rem;

            .header__logo {
                font-size: 2rem;
            }

            .header__hamburger {
                width: 30px;
                height: 24px;

                div {
                    width: 30px;
                    height: 3px;
                }
            }
        }

        .header__bottom {
            .header__content {
                gap: var(--paddingl);

                .header__social {
                    li {
                        font-size: 4.5rem;
                        padding: 2rem 0;
                    }
                }
            }

            .header__copyright {
                li {
                    p {
                        font-size: 0.75rem;
                    }
                }
            }
        }

        &.open {
            .header__bottom {
                gap: var(--paddingl);
            }
        }
    }
}

@media (min-width: 769px) and (max-width: 992px) {
    .header {
        padding: var(--paddings);

        .header__top {
            font-size: 2.5rem;

            .header__logo {
                font-size: 2.5rem;
            }

            .header__hamburger {
                width: 40px;
                height: 32px;

                div {
                    width: 40px;
                    height: 4px;
                }
            }
        }

        .header__bottom {
            .header__content {
                padding: var(--paddings);

                .header__social {
                    li {
                        font-size: 5rem;
                        padding: 2.5rem 0;
                    }
                }
            }

            .header__copyright {
                li {
                    p {
                        font-size: 0.75rem;
                    }
                }
            }
        }

        &.open {
            .header__bottom {
                gap: var(--paddingl);
            }
        }
    }
}

@media (max-width: 992px) {
    .header {
        top: 0 !important;
        padding: var(--paddings);
        z-index: 99999;

        .header__top {
            font-size: 2.5rem;

            .header__social {
                display: none !important;
            }
        }

        .header__bottom {
            display: none;
            transition: 0.7s all ease;
        }
    }
}

// desktop header starts here
@media (min-width: 993px) {
    .header {
        .header__top {
            .header__hamburger {
                display: none;
            }
        }

        .header__bottom {
            .header__social {
                display: none;
            }
        }
    }
}

@media (min-width: 993px) and (max-width: 1200px) {
    .header {
        padding: var(--paddingl);

        .header__top {
            font-size: 2.5rem;

            .header__logo {
                font-size: 2.5rem;
            }
        }

        .header__bottom {
            .header__copyright {
                li {
                    p {
                        font-size: 1rem;
                    }
                }
            }
        }
    }
}

@media (min-width: 1201px) {
    .header {
        padding: var(--paddingl);

        .header__top {
            font-size: 2.75rem;

            .header__logo {
                font-size: 2.75rem;
            }
        }

        .header__bottom {
            .header__copyright {
                li {
                    p {
                        font-size: 1rem;
                    }
                }
            }
        }
    }
}